import React from "react";
import "./MessageInvoiceCard.css";
import MessageInvoiceCardDepositInfo from "./MessageInvoiceCardDepositInfo";
import MessageInvoiceCardExpired from "./MessageInvoiceCardExpired";
import PosVisibility from "../../components/PosVisibility";
import { formatCurrency } from "../../lib/currency";

export const InvoiceCard = ({
  status,
  total,
  currency,
  description,
  depositStatus,
  depositError,
  invoiceId,
  externalLink,
}) => {
  let statusColor = "secondary";

  switch (status) {
    case "paid":
      statusColor = "success";
      break;
  }

  return (
    <div className="ms-auto card card-invoice">
      <div className="card-body">
        <div className="d-flex">
          <b className=" flex-grow-1">Invoice</b>
          <div>
            <span className={`badge text-capitalize text-bg-${statusColor}`}>
              {status === "finalized" ? "Open" : status}
            </span>
          </div>
        </div>
        <p className="card-invoice-amount text-primary">
          {formatCurrency(total, currency)}
        </p>
        <p className="card-invoice-description">{description}</p>
        <a
          className="text-secondary"
          href={externalLink}
          target="_blank"
          rel="noreferrer"
        >
          Open in Stripe
        </a>
        <PosVisibility showLightspeed>
          <MessageInvoiceCardDepositInfo
            status={depositStatus}
            depositError={depositError}
          />
        </PosVisibility>
        <MessageInvoiceCardExpired status={status} invoiceId={invoiceId} />
      </div>
    </div>
  );
};

export default InvoiceCard;
