import React from "react";
import { useLocation } from "react-router-dom";
import { SupportAnchor } from "../lib/ikeono";
import { useState } from "../lib/overmind";
import SimpleHeaderBodyBanner from "./SimpleHeaderBodyBanner";

const UserPermissionCheck = ({ children, permissionKey, exceptionPaths }) => {
  const userPermissions = useState().account?.data?.user?.permissions;

  const location = useLocation(); // This isn't used, but is needed to rerender for the exceptionPaths

  if (!userPermissions)
    return (
      <div>
        Unable to validate user perfmissions. Contact support. <SupportAnchor />
      </div>
    );
  if (!permissionKey) {
    throw new Error("permission key is required");
  }
  if (!Object.hasOwn(userPermissions, permissionKey)) {
    throw new Error(`Invalid permissin key: ${permissionKey}`);
  }

  if (exceptionPaths) {
    for (const path of exceptionPaths) {
      if (window.location.href.includes(path)) {
        return children;
      }
    }
  }

  if (!userPermissions[permissionKey]) {
    return (
      <SimpleHeaderBodyBanner
        title="You do not have access to this feature."
        body="Pleae contact the owner of this account to give you access."
      />
    );
  }

  return children;
};

export default UserPermissionCheck;
