import React, { useEffect } from "react";
import Checkbox from "../components/Form/Checkbox";
import LabeledField from "../components/Form/LabeledField";
import SimpleForm from "../components/Form/SimpleForm";
import InformationTooltip from "../components/InformationTooltip";

const UserDetailForm = ({ formData, apiSubmitHandler, shops }) => {
  return (
    <SimpleForm
      id="user-detail-form"
      hideSubmitButton
      initialValues={formData}
      apiSubmitHandler={apiSubmitHandler}
    >
      <LabeledField
        name="email"
        label="Email"
        required
        type="email"
        disabled={formData?.email}
        className="form-control form-control-sm"
      />
      <LabeledField
        name="first_name"
        label="First Name"
        className="form-control form-control-sm"
      />
      <LabeledField
        name="last_name"
        label="Last Name"
        className="form-control form-control-sm"
      />
      {!formData?.email && (
        <LabeledField
          hidden={formData?.email}
          name="password"
          label="Password"
          className="form-control form-control-sm"
          required
          minLength={10}
        />
      )}

      <div className="mt-3" />
      <span className="fw-bold">Locations</span>
      <div className="mt-1">
        {shops.map((shop) => {
          const id = `shop_${shop.id}`;
          return <Checkbox key={id} id={id} label={shop.name} />;
        })}
      </div>
      <div className="fw-bold mt-3">Permissions</div>
      <div className="mt-1">
        <Checkbox id="permission_can_send_messages" label="Can send messages" />
        <Checkbox id="permission_can_view_settings" label="Can view settings" />
        <Checkbox
          id="permission_can_view_feedback"
          label="Can view Reviews and NPS"
        />
        <Checkbox
          id="permission_can_edit_nps_score"
          label="Can edit NPS score"
        />
        <Checkbox
          id="permission_can_edit_reviews"
          label="Can reply to Reviews"
        />
        <Checkbox id="permission_can_manage_users">
          Can manage users{" "}
          <InformationTooltip
            title="Be careful, this allows the user to view, create and update users on
            your account"
          >
            <span className="d-block" style={{ fontSize: "12px" }}>
              <i className="fs-13 bi-info-circle cursor-help me-1" />
              Caution, this allows the user to view, create and update users on
              your account.
            </span>
          </InformationTooltip>
        </Checkbox>
      </div>
    </SimpleForm>
  );
};

export default UserDetailForm;
