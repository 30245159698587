import React, { useEffect, useState } from "react";

import UserPermissionCheck from "../../components/UserPermissionCheck";
import {
  BrowserExtensionAppIcon,
  EcwidAppIcon,
  GoogleAppIcon,
  LightspeedRSeriesAppIcon,
  LightspeedXSeriesAppIcon,
  MessengerAppIcon,
  StripeAppIcon,
  WebchatAppIcon,
} from "../../IntegrationAppIcon";
import Loader from "../../components/Loader";
import APIResponseError from "../../components/APIResponseError";
import DefaultIntegrationCard from "./DefaultIntegrationCard";
import WarningIntegrationCard from "./WarningIntegrationCard";
import InstalledIntegrationCard from "./InstalledIntegrationCard";
import * as overmind from "../../lib/overmind";

const integrationDetails = {
  stripe: {
    icon: <StripeAppIcon />,
    title: "Payments",
    body: "Send a secure payment link to customers to capture payments on invoices, quotes, or work orders. ",
    url: "/settings/location/payments/connection",
  },
  google: {
    icon: <GoogleAppIcon />,
    title: "Google",
    body: "Setup Google Business Profile to setup your reviews, sync your store hours and more.",
    url: "/settings/location/google/oauth",
  },
  lightspeed_r_series: {
    icon: <LightspeedRSeriesAppIcon />,
    title: "Lightspeed R-Series",
    body: "Send messages, invoice links, automated messages and more when you connect Lightspeed.",
    url: "/settings/location/lightspeed-r-series/store-assignment",
  },
  lightspeed_x_series: {
    icon: <LightspeedXSeriesAppIcon />,
    title: "Lightspeed X-Series",
    body: "Send messages, invoice links, and more when you connect Vend.",
    url: "/settings/location/lightspeed-x-series/store-assignment",
  },
  browser_extension: {
    icon: <BrowserExtensionAppIcon />,
    title: "Browser Extension",
    body: "The Ikeono Browser extension adds functionality to your Point of Sale system.",
    url: "/settings/location/browser-extension",
    status: null,
  },
  webchat_widget: {
    icon: <WebchatAppIcon />,
    title: "Webchat Widget",
    body: "Capture leads while they are on your website with an easy-to-install button that converts leads into customers.",
    url: "/settings/location/website-widget",
  },
  messenger: {
    icon: <MessengerAppIcon />,
    title: "Facebook Messenger",
    body: "View and reply to Facebook Messenger conversations from within Ikeono.",
    url: "/settings/location/meta/messenger",
  },
  ecwid: {
    icon: <EcwidAppIcon />,
    title: "Ecwid",
    body: "Add Stripe as a payment option to your Ecwid Store",
    url: "/settings/location/ecwid",
  },
};

const Integrations = () => {
  const actions = overmind.useActions().integrations;
  const [integrations, setIntegrations] = useState(null);
  const state = overmind.useState();

  useEffect(() => {
    actions.get();
  }, []);

  useEffect(() => {
    state.integrations.integrations?.forEach((i) => {
      const integration = integrationDetails[i.id];
      if (integration) {
        integration.status = i.status;
      }
    });
    integrationDetails.browser_extension.status = state.browserExtensionEventBus
      .isExtensionInstalled
      ? "installed"
      : null;
    const integrationVals = Object.keys(integrationDetails).map(
      (key) => integrationDetails[key]
    );
    setIntegrations(integrationVals);
  }, [
    state.integrations.integrations,
    state.browserExtensionEventBus.isExtensionInstalled,
  ]);

  if (state.integrations.isLoading) return <Loader />;
  if (state.integrations.apiError) {
    return (
      <div className="d-flex flex-column align-items-center mt-5">
        <div>
          <APIResponseError response={state.integrations.apiError} />
        </div>
        <div>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => actions.get()}
          >
            Reload
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="d-flex flex-wrap gap-4 mt-4">
        {integrations?.map((integration, i) => {
          let CardClass = DefaultIntegrationCard;
          if (integration.status === "warning") {
            CardClass = WarningIntegrationCard;
          } else if (integration.status === "installed") {
            CardClass = InstalledIntegrationCard;
          }
          return <CardClass key={i} {...integration} />;
        })}
      </div>
    </div>
  );
};

export default Integrations;
