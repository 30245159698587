import React, { useEffect } from "react";
import DataTable from "../../components/DataTable";
import { getVariant } from "../rating";

const NpsDetractorList = ({ detractors }) => {
  const [rows, setRows] = React.useState();

  useEffect(() => {
    const computedRows = [];

    // eslint-disable-next-line array-callback-return
    detractors?.map((detractor) => {
      computedRows.push({
        id: detractor.id,
        columns: [
          {
            value: detractor.score,
            type: "badge",
            ui_variant: getVariant(detractor.score),
          },
          {
            value: detractor.source,
          },
          {
            value: detractor.date_sent,
            type: "date",
          },
          {
            value: detractor.note,
            className: "text-lower",
          },
          {
            value: "View Details",
            type: "link",
            url: `${window.location.href}/replies/${detractor.id}`,
          },
        ],
      });
    });

    setRows(computedRows);
  }, [detractors]);

  return (
    <div className="card">
      <div className="card-header bg-transparent">
        Detractors For Current Period
      </div>
      <div className="card-body">
        <DataTable
          rows={rows}
          headers={["Score", "Source", "Date", "Private Note", "Detail"]}
        />
      </div>
    </div>
  );
};

export default NpsDetractorList;
