import React from "react";
import { Link } from "react-router-dom";
import "./MessageWidgetCard.css";
import * as image from "../lib/image";
import { WebchatAppIcon } from "../IntegrationAppIcon";
import { useState } from "../lib/overmind";

const ProductInfo = ({ make, model }) => {
  if (!make && !model) return null;
  return (
    <div className="d-flex fs-13 gap-2">
      <span>
        <i className="bi-card-list" /> {make} {model}
      </span>
    </div>
  );
};

const PageLink = ({ children, hidden }) => {
  if (hidden) return null;
  return (
    <a
      target="_blank"
      style={{ marginTop: "-3px" }}
      className="d-flex flex-row justify-content-center text-secondary justify-content-center fs-13"
      href={children}
      rel="noreferrer"
    >
      <u className="text-nowrap overflow-hidden">{children}</u>
    </a>
  );
};

const SpecializedWebchatIcon = () => {
  return (
    <img
      className="border rounded border-black "
      src="/specialized-icon.png"
      height="25px"
      width="25px"
    />
  );
};

export const MessageWidgetCard = ({ message }) => {
  const make = message.meta?.context?.make;
  const model = message.meta?.context?.model;
  const imageUrl =
    message.meta?.context?.image_url || image.placeholderImageUrl;
  const pageUrl = message.meta?.context?.url;

  const icon = pageUrl?.includes("specialized.com") ? (
    <SpecializedWebchatIcon />
  ) : (
    <WebchatAppIcon width="33px" height="33px" />
  );

  const state = useState().account;

  if (!state) return null;

  const hasPermission =
    !state.data.config.portal.website_widget.needs_account_upgrade;

  return (
    <div className="card card-website-widget">
      <div className="card-body">
        <div className="mb-2 d-flex flex-row gap-2">
          {icon}
          <div className="overflow-hidden">
            <span className="message-source flex-grow-1 fw-bold d-block fs-14">
              Webchat Message
            </span>
            <PageLink hidden={!pageUrl}>{pageUrl}</PageLink>
          </div>
        </div>
        <ProductInfo make={make} model={model} />
        <p className={`mt-1 mb-0 ${hasPermission ? "" : "blur-3"}`}>
          {hasPermission
            ? message?.body
            : "Please upgrade to start seeing your messages!"}
        </p>
        <div hidden={hasPermission} className="mt-3 alert alert-warning">
          <div>Please upgrade your account to see this message.</div>
          <Link
            className="btn btn-sm btn-primary mt-1"
            to="/settings/location/website-widget"
          >
            Upgrade Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MessageWidgetCard;
