import React from "react";

const IconMap = {
  2: "bi-currency-dollar",
  3: "bi-robot",
  6: "bi-google",
};

const BackgroundColorMap = {
  6: "rgb(48, 125,246)",
};

const InboxRowStatus = ({ status }) => {
  if (!status || status > 7) return null;

  if (status === 1) {
    return <i className="bi-reply" />;
  }

  if (status === 4) {
    return (
      <span className="inbox-row-status text-bg-danger">
        <i className="bi-exclamation-lg" />
      </span>
    );
  }

  if (status === 5) return null;

  if (status === 7) {
    return (
      <img
        src="/specialized-icon.png"
        height="25px"
        width="25px"
        className="rounded border border-black"
      />
    );
  }

  return (
    <span
      className="inbox-row-status"
      style={{ backgroundColor: BackgroundColorMap[status] }}
    >
      <i className={IconMap[status]} />
    </span>
  );
};

export default InboxRowStatus;
